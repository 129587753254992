'use client'

import styles from './Button.module.scss'
import classNames from 'classnames/bind'
import React, { useState } from 'react'

import Modal from '@/components/Elements/Modal/Modal'
import VideoEmbed from '@/components/Elements/VideoEmbed/VideoEmbed'
import Icon from '@/components/Elements/Icon/Icon'

const classes = classNames.bind(styles)

const Button = React.forwardRef(({ ...props }, ref) => {
    const {
        newTab,
        type = 'primary',
        extraClasses,
        text,
        icon,
        iconSide,
        url,
        disabled,
        videoLink,
        style = {},
        onClick = null,
    } = props

    const [showVideo, updateShowVideo] = useState(false)

    return (
        <>
            <a
                className={classes(
                    'button',
                    ...type.split(' '),
                    {
                        disabled,
                        'left-icon': iconSide == 'lefty',
                    },
                    extraClasses
                )}
                target={videoLink ? undefined : newTab ? '_blank' : null}
                href={videoLink ? undefined : url}
                onClick={videoLink ? () => updateShowVideo(true) : onClick}
                style={style}
                ref={ref}
            >
                <span>{text}</span>
                {icon && <Icon icon={icon} className={classes('icon')} />}
            </a>
            {videoLink && <VideoModal src={videoLink} updateShowVideo={updateShowVideo} display={showVideo} />}
        </>
    )
})

const VideoModal = ({ display, src, updateShowVideo }) => {
    if (!src) return null

    return (
        <Modal open={display} setOpen={updateShowVideo} className={classes('video-popup')}>
            <VideoEmbed src={src} large={true} />
        </Modal>
    )
}

export default Button

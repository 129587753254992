const svgDir = require.context('!@svgr/webpack!/public/images/', true, /\.svg$/)

/**
 * This is a JavaScript function that renders an SVG icon based on the provided category and name.
 * @returns an SVG icon component.
 */

export default function Icon({ icon, category = 'icon-component', ...props }) {
    if (!icon) return
    try {
        const Icon = svgDir(`./${category}/${icon}.svg`).default

        if (!Icon) return console.error(`Icon ${icon} not found`)

        return <Icon {...props} />
    } catch (e) {
        console.error(e)
    }
}

import styles from './VideoEmbed.module.scss'
import classNames from 'classnames/bind'

const classes = classNames.bind(styles)
/**
 * A video embed component to handle making a video responsive.
 *
 * @param {String} src The video source href.
 * @returns {String} A responsive video embed.
 */
export default function VideoEmbed({ src, large = false }) {
    if (!src) return null

    let style = { margin: '24px 0' }
    let width = '560'
    let height = '315'

    if (large) {
        style = {}
        width = '1080'
        height = '720'
    }

    return (
        <div style={style}>
            <div className={classes('video-container')}>
                <iframe
                    width={width}
                    height={height}
                    src={src}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ marginBottom: '24px' }}
                ></iframe>
            </div>
        </div>
    )
}

import React, { useEffect, useState, useRef, forwardRef } from 'react'
import ReactDOM from 'react-dom'

import { CSSTransition } from 'react-transition-group'

import Icon from '@/components/Elements/Icon/Icon'

import styles from './Modal.module.scss'
import classNames from 'classnames/bind'
const classes = classNames.bind(styles)

/**
 * Creates a modal on the page at the body tag.
 *
 * @param {Boolean} open A boolean determining whether the modal should be showing or not.
 * @param {Function} setOpen A function to allow the modification of the open property.
 * @param {Element} children Any child elements.
 *
 */
export default forwardRef(
    ({ open, setOpen, children, className, allowClose = true, large = false, noPadding = false }, ref) => {
        // Set state to determine if the browser is loaded, default to false.
        const [browserLoaded, setBrowserLoaded] = useState(false)
        const nodeRef = useRef(null)
        // Use useEffect to set browser loaded to true.
        useEffect(() => {
            setBrowserLoaded(true)
        })

        if (!browserLoaded) return null

        // Create a portal to add the overlay to the body of the document to avoid overflow issues.
        return ReactDOM.createPortal(
            <CSSTransition
                unmountOnExit
                mountOnEnter
                in={open}
                timeout={300}
                classNames={{
                    enter: classes('animate-enter'),
                    enterActive: classes('animate-enter-active'),
                    exit: classes('animate-exit'),
                    exitActive: classes('animate-exit-active'),
                }}
                nodeRef={nodeRef}
            >
                <div
                    className={classes('overlay')}
                    onMouseDown={(e) => {
                        if (!allowClose) return
                        // Allow the user to click on the scroll bar without the modal closing. The scrollbar is 10px wide.
                        if (e.clientX < window.innerWidth - 10 && e.target == e.currentTarget) {
                            setOpen(false)
                        }
                    }}
                    ref={nodeRef}
                >
                    <div className={classes('popup-wrapper')}>
                        <div className={classes(`popup`, { large, 'no-padding': noPadding }, className)} ref={ref}>
                            {/* Add a close button function to close the popup. */}
                            <div
                                className={classes('close-button', { hidden: !allowClose })}
                                onClick={() => {
                                    if (!allowClose) return
                                    setOpen(false)
                                }}
                            >
                                <Icon category="icons" icon="close" />
                            </div>
                            {/* Add the children to the content part of the popup. */}
                            {children}
                        </div>
                    </div>
                </div>
            </CSSTransition>,
            // Add to the document body.
            document.body
        )
    }
)
